<template>
  <v-skeleton-loader
    v-if="loading"
    type="article, actions"
  ></v-skeleton-loader>

  <v-card
    v-else
    :loading="loading"
  >
    <v-card-title class="d-flex justify-space-between">
      <h4>Ajouter un utilisateur</h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiAccountPlus }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label for="firstname">Prénom</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="firstname"
              v-model="newUser.firstName"
              :rules="[v => !!v || 'Le prénom est requis']"
              outlined
              dense
              placeholder="Prénom"
              @change="updateNames"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="lastname">Nom</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="lastname"
              v-model="newUser.lastName"
              :rules="[v => !!v || 'Le nom est requis']"
              outlined
              dense
              placeholder="Nom"
              @change="updateNames"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="displayName">Nom d'affichage</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="displayName"
              v-model="newUser.displayName"
              :rules="[v => !!v || 'Le nom d\'affichage est requis']"
              placeholder="Nom d'affichage"
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="email">E-mail</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="email"
              v-model="newUser.email"
              :rules="emailRules"
              md="9"
              outlined
              dense
              placeholder="E-mail"
              @change="error === false"
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="status">Rôle</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-select
              id="status"
              v-model="newUser.status"
              :rules="[v => !!v || 'Le rôle est requis']"
              outlined
              dense
              :items="statusList"
              item-text="text"
              item-value="statusName"
              placeholder="Rôle"
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-5">
      <v-btn
        class="mx-2"
        outlined
      >
        Annuler
      </v-btn>
      <v-btn
        color="primary"
        @click="createUser"
      >
        Créer l'utilisateur
      </v-btn>
    </v-card-actions>
    <v-card-text v-if="error">
      <v-alert
        prominent
        type="warning"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ errorMsg }}
          </v-col>
          <v-col
            v-if="errorType ==='notInvited'"
            class="shrink"
          >
            <v-btn @click="inviteUser">
              Inviter
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>

import { mdiAccountPlus } from '@mdi/js'

export default {
  data() {
    return {
      valid: true,
      loading: true,
      emailRules: [
        !this.alreadyUser || 'Cet utilisateur est déjà dans votre entreprise.',
        v => !v || /.+@.+\..+/.test(v) || 'L\'adresse e-mail doit être valide',
      ],
      error: false,
      errorType: '',
      alreadyUser: false,
      errorMsg: '',
      newUser: {
        firstName: '',
        lastName: '',
        status: null,
        email: '',
        displayName: '',
        confirmed: false,
      },
      icons: {
        mdiAccountPlus,
      },
      statusList: [
        {
          text: 'Administrateur',
          statusName: 'admin',
        },
        {
          text: 'Utilisateur',
          statusName: 'user',
        },
        {
          text: 'Tuteur',
          statusName: 'tuteur',
        },
      ],
    }
  },
  mounted() {
    this.loading = false
  },
  methods: {
    createUser() {
      this.error = false
      this.errorType = ''
      if (this.$store.state.userlist.some(element => element.user.email === this.newUser.email)) {
        this.error = true
        this.errorMsg = 'Cet utilisateur est déjà dans votre entreprise.'
      } else if (this.$refs.form.validate()) {
        this.loading = true
        this.$http.post(`${process.env.VUE_APP_API_URL}/users/create`,
          this.newUser)
          .then(() => {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Nouvel utilisateur créé',
              value: true,
            })
            this.newUser = {
              firstName: '',
              lastName: '',
              status: null,
              email: '',
              displayName: '',
              confirmed: false,
            }
          })
          .catch(err => {
            this.error = ((err.response.status === 409) && (err.response.data.key === 'email'))
            this.errorType = 'notInvited'
            this.errorMsg = `L'adresse e-mail ${this.newUser.email} est déjà associée à un compte Jimbo. L'inviter a rejoindre ${this.$store.state.company.name} ?`
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la création de l\'utilisateur',
              value: true,
            })
          })
          .finally(
            this.loading = false,
          )
      }
    },
    inviteUser() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/company-invite/new`, { email: this.newUser.email })
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Invitation envoyée avec succès',
            value: true,
          })
          this.error = false
          this.errorType = ''
          this.alreadyUser = false
          this.errorMsg = ''
        })
        .catch(err => console.log(err))
        .finally()
    },
    updateNames() {
      this.newUser.username = this.newUser.firstName.toLowerCase().replace(/[^a-zA-Z]/ig, '')
      this.newUser.displayName = `${this.newUser.firstName.charAt(0).toUpperCase()}${this.newUser.firstName.slice(1).toLowerCase()} ${this.newUser.lastName.charAt(0).toUpperCase()}.`
    },
  },
}
</script>

<style>

</style>
